import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from './global/api.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'astaGuru';
  currencyValue : any;
  // config: {}
  constructor(
    public router: Router,
    public api : ApiService
  ) {

  }

  ngOnInit() {
    this.storeCurrenctValue();
    // for input focus
    $(document).find('input , select').focus(function () {
      $('.input-ele').removeClass('active');
      $(this).parent('.input-ele').addClass('active');
    })
    $('input , select').blur(function () {
      $('.input-ele').removeClass('active');
    })
    // for input focus end

    $(document).find('.inventory-category').click(function () {
      $('.close').trigger('click');
    })
  }

  storeCurrenctValue(){
    this.api.convertUSDToINR().subscribe((res) => {
      this.currencyValue = {
        'USD': res['rates'] == null ? "" : res['rates'].INR
      }

      this.api.convertEURToINR().subscribe((res) => {
        this.currencyValue['EUR'] = res['rates'].INR;

        sessionStorage.setItem('currencyRate', JSON.stringify(this.currencyValue));
        // sessionStorage.setItem('globalCurrencyType', 'INR');
      }, (error) => {
        //console.log(error);
      });

      
    }, (error) => {
      //console.log(error);
    });

    // this.api.convertEURToINR().subscribe((res) => {
    //   this.currencyValue = {
    //     'EUR' : res['rates'].INR
    //   }
    // }, (error) => {
    //   //console.log(error);
    // });

    
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// let baseUrl = 'https://crm.astaguru.com/prime_agcrm/WebApi/';
// let baseUrl = 'http://202.134.185.91:1500/Astaguru/v2/api/';

// let baseUrl = 'http://202.134.185.91:1490/Astaguru/v1/api/';
// let baseUrl = 'http://202.134.185.91:1490/Astaguru/v2/api/';

//prod
//let baseUrl = 'http://api.astaguru.com:8080/';

//New
//let baseUrl = 'http://localhost:58532/';//'http://api.astaguru.com/';
let baseUrl = 'https://crm-api-uat.astaguru.com/';
//test

//let baseUrl = 'http://astaapi.ikaart.org/';
 
//let baseUrl = 'http://localhost:58532/';


export const environment = {
  production: false,

  apiUrl: baseUrl,
  UserTypeIDForDealer: '3',
  imageSizeForProfile: 5,
  imageSizeForGallery: 10,
   //To Make a Phone call url
   MakeaphonecallUri:'http://103.142.115.172:8888/Clicktocall/Default.aspx?',
   PhonecallUniqueId:'&uniquid=1',
  getDealerList: baseUrl + 'DealerManagement/GetAllDealer',

  //dropdown url
  masterStateList: baseUrl + 'Master/GetStateForDropdown',
  masterCityList: baseUrl + 'Master/GetCityForDropdown',
  masterCountryList: baseUrl + 'Master/GetCountryForDropdown',
  masterCRStatusList: baseUrl + '',
  masterAdminStatusList: baseUrl + '',
  masterGetCategoryForDropdown: baseUrl + 'Master/GetCategoryForDropdown',
  masterGetSizeForDropdown: baseUrl + 'Master/GetSizeForDropdown',
  masterGetmediumForDropdown: baseUrl + 'Master/GetmediumForDropdown',
  masterGetPaintingMediumsForDropdown: baseUrl + 'Master/GetPaintingMediumsForDropdown',
  masterGetSculptureMediumsForDropdown: baseUrl + 'Master/GetSculptureMediumsForDropdown',
  masterGetStyleOfPaintingForDropdown: baseUrl + 'Master/GetStyleOfPaintingForDropdown',
  masterGetDeliveryCompanyForDropdown: baseUrl + 'Master/GetDeliveryCompanyForDropdown',
  MasterGetPhilatelicAndNumismaticCategoryForDropdown: baseUrl + 'Master/GetPhilatelicAndNumismaticCategoryForDropdown',
  masterGetPhilatelicAndNumismaticTypeForDropdown: baseUrl + 'Master/GetPhilatelicAndNumismaticTypeForDropdown',
  masterLocationForDropdown: baseUrl + 'Master/GetLocationForDropdown',
  masterGetEmbroideryWorkTypeForDropdown: baseUrl + 'Master/GetEmbroideryWorkTypeForDropdown',
  masterGetFurnitureTypeForDropdown: baseUrl + 'Master/GetFurnitureTypeForDropdown',
  masterHSNForDropdown: baseUrl + 'Master/GetHSNForDropdown',
  masterGetAllAuctionsorDropdown: baseUrl + 'Master/GetAllAuctionForDropdown',
  masterGetWeightForDropdown: baseUrl + 'Master/GetWeightForDropdown',
  masterGetTimepiecesAndClocksMovementForDropdown: baseUrl + 'Master/GetTimepiecesAndClocksMovementForDropdown',
  masterGetTimepiecesAndClocksWatchTypeForDropdown: baseUrl + 'Master/GetTimepiecesAndClocksWatchTypeForDropdown',
  roleModuleMasterGetModuleForDropdown: baseUrl + 'RoleModuleMaster/GetModuleForDropdown',
  masterGetClientPriorityForDropdown: baseUrl + 'Master/GetClientPriorityForDropdown',
  masterGetOpportunityClientListForDropdown: baseUrl + 'Master/GetOpportunityClientListForDropdown',
  //get all urls
  getAllAdminMasterList: baseUrl + 'AdminMaster/GetAllAdminMaster',
  getAllCRClient: baseUrl + 'crManagement/GetAllCR',
  getAllClient: baseUrl + 'ClientManagement/getallclient',


  //add edit and delete urls
  InsertUpdateDealer: baseUrl + 'DealerManagement/InsertUpdateDealer',
  InsertUpdateCR: baseUrl + 'CRManagement/InsertUpdateCR',
  InsertUpdateAdminMaster: baseUrl + 'AdminMaster/InsertUpdateAdminMaster',
  InsertUpdateClient: baseUrl + 'ClientManagement/InsertUpdateClient',

  //login
  LoginUrl: baseUrl + 'Login/UserLogin',
  checkLinkExpire: baseUrl + 'Login/CheckLinkExpiration',
  setUserNameAndPass: baseUrl + 'Login/SetPassword',

  //file upload urls
  imageUpload: baseUrl + 'common/UploadImageFiles',
  uploadDocuments: baseUrl + 'common/UploadDocuments',
  uploadExcelFile: baseUrl + 'common/UploadExcelFile',
  //masters urls
  //Artist
  masterGetAllArtist: baseUrl + 'Master/GetAllArtist',
  masterDeleteArtist: baseUrl + 'Master/DeleteArtist',
  masterGetArtistByID: baseUrl + 'Master/GetArtistByID',
  masterInsertUpdateArtist: baseUrl + 'Master/InsertUpdateArtist',
  MasterChangeArtistStatus: baseUrl + 'Master/ChangeArtistStatus',
  changeStatus: baseUrl + 'Master/ChangeStatus',
  changeVerifyStatus: baseUrl + 'Master/ChangeStatus',
  //Location
  masterGetAllLocation: baseUrl + 'Master/GetAllLocation',
  masterDeleteLocation: baseUrl + 'Master/DeleteLocation',
  masterGetLocationByID: baseUrl + 'Master/GetLocationByID',
  masterInsertUpdateLocation: baseUrl + 'Master/InsertUpdateLocation',
  masterGetLogisticForDropdown: baseUrl + 'Master/GetLogisticForDropdown',
  MasterChangeLocationStatus: baseUrl + 'Master/ChangeLocationStatus',

  //Style OF Painting
  masterGetAllStyleOfPainting: baseUrl + 'Master/GetAllStyleOfPainting',
  masterDeleteStyleOfPainting: baseUrl + 'Master/deleteStyleOfPainting',
  masterGetStyleOfPaintingByID: baseUrl + 'Master/GetStyleOfPaintingByID',
  masterInsertUpdateStyleOfPainting: baseUrl + 'Master/InsertUpdateStyleOfPainting',
  MasterChangeStyleOfPaintingStatus: baseUrl + 'Master/ChangeStyleOfPaintingStatus',
  //Style of sculpture
  masterInsertUpdateStyleOfSculpture: baseUrl + 'Master/InsertUpdateStyleOfSculpture',
  masterGetStyleOfSculptureByID: baseUrl + 'Master/GetStyleOfSculptureByID',
  masterGetAllStyleOfSculpture: baseUrl + 'Master/GetAllStyleOfSculpture',
  masterDeleteStyleOfSculpture: baseUrl + 'Master/DeleteStyleOfSculpture',
  MasterChangeStyleOfSculptureStatus: baseUrl + 'Master/ChangeStyleOfSculptureStatus',


  //Consignor master

  masterInsertUpdateConsignor: baseUrl + 'master/InsertUpdateConsignor',
  masterGetAllConsignor: baseUrl + 'master/GetAllConsignor',
  masterGetConsignorByID: baseUrl + 'master/GetConsignorByID',
  masterDeleteConsignor: baseUrl + 'master/DeleteConsignor',
  masterGetConsignorForDropdown: baseUrl + 'master/GetConsignorForDropdown',

  //Logistics
  masterInsertUpdateLogistic: baseUrl + 'Master/InsertUpdateLogistic',
  masterGetLogisticByID: baseUrl + 'Master/GetLogisticByID',
  masterGetAllLogistic: baseUrl + 'Master/GetAllLogistic',
  masterDeleteLogistic: baseUrl + 'Master/DeleteLogistic',
  MasterChangeLogisticStatus: baseUrl + 'Master/ChangeLogisticStatus',

  //Style OF Painting
  masterGetAllTag: baseUrl + 'Master/GetAllTag',
  masterDeleteTag: baseUrl + 'Master/DeleteTag',
  masterGetTagByID: baseUrl + 'Master/GetTagByID',
  masterInsertUpdateTag: baseUrl + 'Master/InsertUpdateTag',
  MasterChangeTagStatus: baseUrl + 'Master/ChangeTagStatus',
  getRacksForDropdown: baseUrl + 'Master/GetRackForDropdown',
   //Style OF rack
 masterGetAllRack: baseUrl + 'Master/GetAllRacks',
 masterDeleteRack: baseUrl + 'Master/DeleteRack',
 masterGetRackByID: baseUrl + 'Master/GetRackByID',
 masterInsertUpdateRack: baseUrl + 'Master/InsertUpdateRack',
 MasterChangeRackStatus: baseUrl + 'Master/ChangeRackStatus',


  //style of print

  masterInsertUpdateStyleOfPrint: baseUrl + 'Master/InsertUpdateStyleOfPrint',
  masterGetAllStyleOfPrint: baseUrl + 'Master/GetAllStyleOfPrint',
  masterDeleteStyleOfPrint: baseUrl + 'Master/DeleteStyleOfPrint',
  masterGetStyleOfPrintByID: baseUrl + 'Master/GetStyleOfPrintByID',
  masterChangeStyleOfPrintStatus: baseUrl + 'Master/ChangeStyleOfPrintStatus',
  masterGetStyleOfPrintForDropdown: baseUrl + 'Master/GetStyleOfPrintForDropdown',


  // Philatelic And Numismatic



  masterInsertUpdatePhilatelicAndNumismaticType: baseUrl + 'Master/InsertUpdatePhilatelicAndNumismaticType',
  masterGetPhilatelicAndNumismaticTypeByID: baseUrl + 'Master/GetPhilatelicAndNumismaticTypeByID',
  masterGetAllPhilatelicAndNumismaticType: baseUrl + 'Master/GetAllPhilatelicAndNumismaticType',
  masterDeletePhilatelicAndNumismaticType: baseUrl + 'Master/DeletePhilatelicAndNumismaticType',
  masterChangePhilatelicAndNumismaticTypeStatus: baseUrl + 'Master/ChangePhilatelicAndNumismaticTypeStatus',


  //medium master
  masterGetAllMedium: baseUrl + 'Master/GetAllMedium',
  masterDeleteMedium: baseUrl + 'Master/DeleteMedium',
  masterGetMediumByID: baseUrl + 'Master/GetMediumByID',
  masterInsertUpdateMedium: baseUrl + 'Master/InsertUpdateMedium',
  MasterChangeMediumStatus: baseUrl + 'Master/ChangeMediumStatus',

  //category master
  masterInsertUpdateCategory: baseUrl + 'Master/InsertUpdateCategory',
  masterGetCategoryByID: baseUrl + 'Master/GetCategoryByID',
  masterGetAllCategory: baseUrl + 'Master/GetAllCategory',
  masterDeleteCategory: baseUrl + 'Master/DeleteCategory',
  MasterChangeCategoryStatus: baseUrl + 'Master/ChangeCategoryStatus',
  MasterChangeConsignorStatus: baseUrl + 'Master/ChangeConsignorStatus',

  //HSN CODE MASTER
  masterInsertUpdateHSN: baseUrl + 'Master/InsertUpdateHSN',
  masterGetAllHSN: baseUrl + 'Master/GetAllHSN',
  masterGetHSNByID: baseUrl + 'Master/GetHSNByID',
  masterDeleteHSN: baseUrl + 'Master/DeleteHSN',
  masterGetHSNForDropdown: baseUrl + 'Master/GetHSNForDropdown',
  masterChangeHSNStatus: baseUrl + 'Master/ChangeHSNStatus',
  masterGetAllHSNForDropdown: baseUrl + 'Master/GetAllHSNForDropdown',


  //by ID
  getClientByID: baseUrl + 'ClientManagement/getclientbyid',
  getCRClientByID: baseUrl + 'CRManagement/GetCRByID',
  getDealerByID: baseUrl + 'DealerManagement/GetDealerByID',
  getAdminMasterByID: baseUrl + 'AdminMaster/GetAdminMasterByID',
  getClientPurchases: baseUrl + 'ClientManagement/GetClientPurchases',

  //delete
  deleteClient: baseUrl + 'ClientManagement/deleteclient',
  deleteCRMgt: baseUrl + 'CRManagement/deleteCR',
  deleteDealer: baseUrl + 'DealerManagement/DeleteDealer',
  deleteAdminMaster: baseUrl + 'AdminMaster/DeleteAdminMaster',

  //approve reject status
  approveRejectClient: baseUrl + 'ClientManagement/ApproveRejectClientByAdmin',
  approveRejectCRMgt: baseUrl + 'CRManagement/ChangeStatus',
  approveRejectAdminMaster: baseUrl + 'AdminMaster/ChangeStatus',
  approveRejectDealer: baseUrl + 'DealerManagement/ChangeStatus',
  // approveRejectClient: baseUrl + 'ClientManagement/ChangeStatus',


  //forgot password
  forgotPassword: baseUrl + 'login/ForgotPassword',

  //inventory 
  getInventoryCategoriesByID: baseUrl + 'Inventory/GetInventoryCategoriesByID',
  getAllInventoryCategories: baseUrl + 'Inventory/GetAllInventoryCategories',
  deleteInventoryCategories: baseUrl + 'Inventory/DeleteInventoryCategories',
  insertUpdatePainting: baseUrl + 'Inventory/InsertUpdatePainting',
  getTagForDropdown: baseUrl + 'Master/GetTagForDropdown',
  getCurrencyForDropdown: baseUrl + 'Master/GetCurrencyForDropdown',
  getArtistForDropdown: baseUrl + 'Master/GetArtistForDropdown',
  ExcelExportAllInventoryCategories: baseUrl + 'Inventory/ExcelExportAllInventoryCategories',//by pradeep

  //sculptures

  insertUpdateInventoryInsertUpdateSculpture: baseUrl + 'Inventory/InsertUpdateSculpture',
  //change password
  changePassword: baseUrl + 'login/ChangePassword',
  //TRAINING
  getAllTrainingMaterial: baseUrl + 'TrainingMaterial/GetAllTrainingMaterial',
  insertTrainingMaterial: baseUrl + 'TrainingMaterial/InsertTrainingMaterial',
  deleteTrainingMaterial: baseUrl + 'TrainingMaterial/DeleteTrainingMaterial',

  //sold & unsold in add painting
  changeStatusInventoryUpdateSoldStatus: baseUrl + 'Inventory/UpdateSoldStatus',
  masterGetStyleOfSculptureForDropdown: baseUrl + 'Master/GetStyleOfSculptureForDropdown',
  getConsignorForDropdown: baseUrl + 'master/GetConsignorForDropdown',


  //Inventory For Print
  insertUpdateInventoryInsertUpdatePrint: baseUrl + 'Inventory/InsertUpdatePrint',

  //Inventory For RareBook

  insertupdateInventoryInsertUpdateRareBook: baseUrl + 'Inventory/InsertUpdateRareBook',

  //Inventory For Textitle

  insertUpdateInventoryInsertUpdateTextTile: baseUrl + 'Inventory/InsertUpdateTextTile',

  //Inventory For neumismatic & Philatelic

  insertUpdateInventoryPhilatelicAndNumismatic: baseUrl + 'Inventory/InsertUpdatePhilatelicAndNumismatic',

  //Inventory For Furniture 

  insertUpdateInventoryFurniture: baseUrl + 'Inventory/InsertUpdateFurniture',
  //Inventory For Fine Writing Instrument Item

  insertUpdateInventoryFineWritingInstrument: baseUrl + 'Inventory/InsertUpdateFineWritingInstrument',

  // Inventory For Memorabilia
  insertUpdateMemorabilia: baseUrl + 'Inventory/InsertUpdateMemorabilia',
  insertUpdateTimepiecesAndClocks: baseUrl + 'Inventory/InsertUpdateTimepiecesAndClocks',

  getMemorabiliaTypeForDropdown: baseUrl + 'Master/GetMemorabiliaTypeForDropdown',

  //Inventory For Science & Natural

  insertUpdateinventoryScienceAndNaturalHistory: baseUrl + 'Inventory/InsertUpdateScienceAndNaturalHistory',
  masterGetSciAndNaturalItemForDropdown: baseUrl + 'Master/GetSciAndNaturalItemForDropdown',
  masterGetSciAndNaturalItemTypeForDropdown: baseUrl + 'Master/GetSciAndNaturalItemTypeForDropdown',
  //Inventory For Jewellery & Silver 

  insertUpdateInventoryJewelleryAndSilver: baseUrl + 'Inventory/InsertUpdateJewelleryAndSilver',
  masterGetJewelleryTypeForDropdown: baseUrl + 'Master/GetJewelleryTypeForDropdown',
  masterGetJewellerySubTypeForDropdown: baseUrl + 'Master/GetJewellerySubTypeForDropdown',


  //Inventory For Classic & Vintage Auto 

  insertUpdateInventoryAutomobile: baseUrl + 'Inventory/InsertUpdateAutomobile',
  masterGetEngineSpecForDropdown: baseUrl + 'Master/GetEngineSpecForDropdown',
  masterGetMileageForDropdown: baseUrl + 'Master/GetMileageForDropdown',
  masterGetTransmissionForDropdown: baseUrl + 'Master/GetTransmissionForDropdown',
  masterGetBodyTypeForDropdown: baseUrl + 'Master/GetBodyTypeForDropdown',

  //Opportunity list 
  getAllApprovedInventoryItems: baseUrl + 'Opportunity/GetAllApprovedInventoryItems',
  getAllOpportunityList: baseUrl + 'Opportunity/GetAllOpportunityList',
  insertAddItemToProspect: baseUrl + 'Opportunity/InsertAddItemToProspect',
  getItemByOpportunity: baseUrl + 'Opportunity/GetItemByOpportunity',

  //Bulk Upload Zip
  excelUploadImportURlJwellarywatch: baseUrl + 'Inventory/ExcelInsertUpdateJewellerySilverWatch',
  excelImportURl: baseUrl + 'ClientManagement/InsertExcelClient',
  commonBulkUploadImageFiles: baseUrl + 'Common/BulkUploadImageFiles',
  getClientManagementGetAllApprovedClient: baseUrl + 'ClientManagement/GetAllApprovedClient',

  opportunityInsertAssignedClientToCR: baseUrl + 'Opportunity/InsertAssignedClientToCR',
  dealerManagementGetAllInventoryCategoriesForDealer: baseUrl + 'DealerManagement/GetAllInventoryCategoriesForDealer',
  insertOpportunityInsertUpdateOpportunity: baseUrl + 'Opportunity/InsertUpdateOpportunity ',
  getOpportunityGetOpportunityByID: baseUrl + 'Opportunity/GetOpportunityByID',
  getOpportunityTrailConversation: baseUrl + 'Opportunity/TrailConversation',
  //Auto search
  opportunityGetAllContactList: baseUrl + 'Opportunity/GetAllContactList',
  masterGetDealerForDropdown: baseUrl + 'Master/GetDealerForDropdown',

  //Dealer DashBoard
  getDealerManagementDealerDashBoard: baseUrl + 'DealerManagement/DealerDashBoard',

  //Approve Reject Status For Admin Dealer Inventory
  updateDealerManagementApproveRejectItemByAdmin: baseUrl + 'DealerManagement/ApproveRejectItemByAdmin',


  //INSTOCK & OUTSSTOCK POPUP
  getInventoryGetAllClientList: baseUrl + 'Inventory/GetAllClientList',
  inventoryUpdateInOutStockItemLocation: baseUrl + 'Inventory/UpdateInOutStockItemLocation',
  insertInOutStockStatus: baseUrl + 'Inventory/InsertInOutStockStatus',
  updateInventoryUpdateClientAddress: baseUrl + 'Inventory/UpdateClientAddress',
  getInventoryGetInOutStockClientWithAddressByID: baseUrl + 'Inventory/GetInOutStockClientWithAddressByID',
  getInventoryGetAllInOutStockList: baseUrl + 'Inventory/GetAllInOutStockList',
  getInventoryGetInOutStockByID: baseUrl + 'Inventory/GetInOutStockByID',


  addIntractionGetDetailsByContactId: baseUrl + '',
  AdminDashBoardAssociationSummary: baseUrl + 'AdminMaster/AdminDashBoardAssociationSummary',
  AdminMasterAdminDashBoardCRKpi: baseUrl + 'AdminMaster/AdminDashBoardCRKpi',
  AdminMasterAdminDashBoardSalesMetric: baseUrl + 'AdminMaster/AdminDashBoardSalesMetric',
  AdminMasterAdminDashBoardTopPerformingCR: baseUrl + 'AdminMaster/AdminDashBoardTopPerformingCR',
  AdminMasterAdminDashBoardMostActiveCR: baseUrl + 'AdminMaster/AdminDashBoardMostActiveCR',
  AdminMasterAdminDashBoardNotifications: baseUrl + 'AdminMaster/AdminDashBoardNotifications',


  //CR Dashboard 
  getCRManagementCRDashBoardMeetingSummary: baseUrl + 'CRManagement/CRDashBoardMeetingSummary',

  getCRManagementCRDashBoardKpi: baseUrl + 'CRManagement/CRDashBoardKpi',
  AddOpportunity: baseUrl + 'Opportunity/InsertOpportunity',
  getCRManagementGetCRSoldItem: baseUrl + 'CRManagement/GetCRSoldItem',
  getOpportunityGetInteractionByID: baseUrl + 'Opportunity/GetInteractionByID',
  getCRManagementClientActivityTracker: baseUrl + 'CRManagement/ClientActivityTracker',

  getCRManagementGetApprovedCR: baseUrl + 'CRManagement/GetApprovedCR',
  InsertReAssignedClientToCR: baseUrl + 'Opportunity/InsertReAssignedClientToCR',
  SendPraposalToUser: baseUrl + 'Inventory/SendPraposalToUser',
  getAdminMasterAdminDashBoardGraph: baseUrl + 'AdminMaster/AdminDashBoardGraph',

  getMasterGetIntrestForDropdown: baseUrl + 'Master/GetIntrestForDropdown',

  getMasterGetAllIntrest: baseUrl + 'Master/GetAllIntrest',
  insertUpdateMasterIntrest: baseUrl + 'Master/InsertUpdateIntrest',
  updateInventorySendItemPraposal: baseUrl + 'Inventory/SendItemPraposal',

  getMasterGetCountryStateByPincode: baseUrl + 'Master/GetCountryStateByPincode',
  
  //praj
  CreateUpdateFormMaster: baseUrl + 'OPS/CreateUpdate_FormMaster',
  deleteFormMaster: baseUrl + 'OPS/DeleteFormMaterData',
  GetFormMaster: baseUrl + 'OPS/Get_FormMaster',
  //pradeep
  getSoldInventoryDataList: baseUrl + 'OPS/GetSoldInventoryCategories',
  getAuctionProductInventoryDataList: baseUrl + 'OPS/GetAuctionProductInventoryCategories',
  //OPS
  getInvoiceMasterList: baseUrl + 'OPS/Get_Invoice_Master',
  getSoldInventoryCategoriesByID: baseUrl + 'OPS/GetSoldInventoryCategoriesByID',
  insertBuyerProformaInvoice: baseUrl + 'OPS/CreateUpdate_ByerProformaInvoice',
  insertBuyerPaymentReceived: baseUrl + 'OPS/CreateUpdate_BuyerPaymentReceived',
  InsertUpdateConsignorTaxInvoice: baseUrl + 'OPS/CreateUpdate_ConsignorTaxInvoiceMaster',
  InsertUpdateInventoryCategories: baseUrl + 'OPS/UpdateInventoryCategory',
  InsertUpdateConsignorConsignorPayment: baseUrl + 'OPS/CreateUpdate_ConsignorPayment',
  InsertUpdateInvoiceTax: baseUrl + 'OPS/CreateUpdate_Invoice_Master',
  InsertUpdateLogistics: baseUrl + 'OPS/CreateUpdate_LogisticsMaster',

  getBuyerProformaInvoiceByInventoryId: baseUrl + 'OPS/GetAllBuyerProformaInvoiceList',
  getBuyerPaymentData: baseUrl + 'OPS/Get_BuyerPaymentReceived',
  getConsignorInvoiceDataByID: baseUrl + 'OPS/GetConsignorInvoiceByID',
  getConsignorPaymentData: baseUrl + 'OPS/Get_ConsignorPayment',
  InsertInvoice: baseUrl + 'OPS/InsertUpdateInvoice',
  addUpdateLogisticsMaster: baseUrl + 'OPS/CreateUpdate_LogisticsMaster',
  getLogisticsData: baseUrl + 'OPS/Get_LogisticsMaster',
  
  generateInvoice: baseUrl + 'OPS/getInvoiceData',
  generateInvoice_bulk: baseUrl + 'OPS/getInvoiceDataInBulk',

  InsertInventoryCompletedStaps: baseUrl + 'OPS/InventoryCompletedStaps',
  GetCategoryTitle: baseUrl + 'OPS/Get_CategoryTitle',
  getVendorMasterList: baseUrl + 'OPS/GetVendorMasterData',
  GetTag: baseUrl + 'OPS/Get_Tag',
  InsertUpdateAuction: baseUrl + 'Master/InsertUpdateAuction',
  GetAllAuctionmaster: baseUrl + 'Master/GetAllAuction',
  getAuctionmasterByID: baseUrl + 'Master/GetAuctionById',
  getAuctionMasterList: baseUrl + 'OPS/GetAuctoinMasterData',
  InsertUpdateAuctionProcuctInventory: baseUrl + 'Master/InsertUpdateAuctionProductInventory',
  getAuctionProcuctInventory: baseUrl + 'Master/GetInventoryProductMaster',
  insertInventorydetails: baseUrl + 'Inventory/InsertInverntoryDetails',
  getAllAuctionProductInventoryCategoriesList: baseUrl + 'Inventory/GetAllAuctionProductInventoryCategoriesList',
  updateAuctionProductInventory: baseUrl + 'Inventory/UpdateAuctionProductInventoryList',
  insertupdateLotMasterData: baseUrl + 'OPS/InsertLotMasterData',
  getLotMasterData: baseUrl + 'OPS/GetLotMasterData',
  getLotMasterDatabyId: baseUrl + 'OPS/GetLotMasterDatabyID',
  getLotMasterDatabyauctionId: baseUrl + 'OPS/GetLotMasterDatabyAuctionID',
  masterDeleteAuction: baseUrl + 'Master/DeleteAuction',
  GetAuctionProductInventoryCategoriesDataById: baseUrl + 'Inventory/GetAuctionProductInventoryCategoriesDataById',
  DeleteInverntoryDetails: baseUrl + 'Inventory/DeleteInverntoryDetails',
  GetAllClientToBlock: baseUrl + 'ClientManagement/GetAllClientToBlock',
  getAllAuctionProductInventoryCategoriesListletest: baseUrl + 'Inventory/GetAllAuctionProductInventoryCategoriesListLetest',
  changeStatusToBlockAuctionClient: baseUrl + 'Master/AuctionBlockClient',


     //Style OF Reason
  masterGetAllReason: baseUrl + 'Master/GetAllReasons',
  masterDeleteReason: baseUrl + 'Master/DeleteReason',
  masterGetReasonByID: baseUrl + 'Master/GetReasonByID',
  masterInsertUpdateReason: baseUrl + 'Master/InsertUpdateReason',
  MasterChangeReasonStatus: baseUrl + 'Master/ChangeReasonStatus',

    //Style OF Events
  masterGetAllEvent: baseUrl + 'Master/GetAllEvents',
  masterDeleteEvent: baseUrl + 'Master/DeleteEvent',
  masterGetEventByID: baseUrl + 'Master/GetEventByID',
  masterInsertUpdateEvent: baseUrl + 'Master/InsertUpdateEvent',
  MasterChangeEventStatus: baseUrl + 'Master/ChangeEventStatus',

  //Reports
  currentBiddersReport: baseUrl + "Report/GetCurrentBiddres",
  underBiddersReport: baseUrl + "Report/GetUnderBiddres",
  lotReferenceNumbers: baseUrl + "Master/GetLotReferenceNo",
  winnerListReport: baseUrl + "Report/GetWinnerList",
  //reset Amount
  resetAmount: baseUrl + "ClientManagement/ResetAmount",
  
   //Style OF Rack

   getReasonsForDropdown: baseUrl + 'Master/GetReasonForDropdown',
   getEventsForDropdown: baseUrl + 'Master/GetEventForDropdown',
   getStatusForDropdown: baseUrl + 'Master/GetStatusForDropdown',

   GetRFIDreports: baseUrl + 'Inventory/GetRFIDreports',
   getInventoryCategoriesByIDwithRFID: baseUrl + 'Inventory/GetInventoryCategoriesByIDWithRFID',
   CheckNewUpdateInventory: baseUrl + 'AssetTracking/CheckNewUpdateInventory',
  GetRFIDLiveReports: baseUrl + 'Inventory/GetRFIDLiveReports',

  downloadWinningEmails: baseUrl + "Master/GetAllEmailDownload",
  senddWinningEmails: baseUrl + "Master/SendWinnigEmail",
  // Pickupdrop status update
  pickUpDropStatus: baseUrl + "OPS/CreateUpdate_FormMaster_Status",

  //SubStatus
  masterInsertUpdateSubstatus: baseUrl + 'Master/InsertUpdateInventorySubstatus',
  masterGetAllSubstatus: baseUrl + 'Master/GetAllInventorySubstatus',
  masterGetSubstatusByID: baseUrl + 'Master/GetInventorySubstatusByID',
  masterDeleteSubstatus: baseUrl + 'Master/DeleteInventorySubstatus',
  getActiveSubstatus: baseUrl + 'Master/GetActiveInventorySubstatus',
  updateSubStatus: baseUrl + 'Inventory/InsertUpdateInventorySubstatus',

  updateInventoryLocation: baseUrl + 'Master/UpdateInventoryLocation',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

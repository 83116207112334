import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGardService } from './global/auth-gard.service';

const routes: Routes = [
  {
    path: 'change-password',
    loadChildren: './components/change-password/change-password.module#ChangePasswordModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'view-Details/:id/:cid',
    loadChildren: './components/view-item-detail/view-item-detail.module#ViewItemDetailModule',
    // canActivate: [AuthGardService]
  },
  {
    path: 'admin-assign-client/:id',
    loadChildren: './components/admin-assign-client/admin-assign-client.module#AdminAssignClientModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'top-performance-active-cr',
    loadChildren: './components/admin-dashboard/top-performance-active-cr/top-performance-active-cr.module#TopPerformanceActiveCrModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'dealer-profile/:id',
    loadChildren: './components/dealer-profile/dealer-profile.module#DealerProfileModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'artist-detail/:id',
    loadChildren: './components/master/artist-detail/artist-detail.module#ArtistDetailModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'inventory-list',
    loadChildren: './components/inventory-list/inventory-list.module#InventoryListModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'dealer-inventory-list',
    loadChildren: './components/dealer-inventory-list/dealer-inventory-list.module#DealerInventoryListModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'client-invoice-detail',
    loadChildren: './components/client-invoice-detail/client-invoice-detail.module#ClientInvoiceDetailModule',
    // canActivate: [AuthGardService]
  },
  {
    path: 'cr-dashboard',
    loadChildren: './components/cr-dashboard/crdashboard/crdashboard.module#CRdashboardModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'dealer-dashboard',
    loadChildren: './components/dealer-dashboard/dealer-dashboard.module#DealerDashboardModule',
    canActivate: [AuthGardService]
  },
  // {
  //   path: 'cr-dashboard',
  //   loadChildren: () => import('./components/cr-dashboard/cr-dashboard.module').then(mod => mod.ClientDashboardModule),
  //   canActivate: [AuthGardService]
  // },

  {
    path: 'traning',
    loadChildren: './components/training-material/training-material.module#TrainingMaterialModule',
    // canActivate: [AuthGardService]
  },
  {
    path: 'master-medium-list',
    loadChildren: './components/master/medium-master/medium-master.module#MediumMasterModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-medium-form',
    loadChildren: './components/master/medium-master-form/medium-master.module#MediumMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-medium-form/:id',
    loadChildren: './components/master/medium-master-form/medium-master.module#MediumMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-tag-list',
    loadChildren: './components/master/tag-master/tag-master.module#TagMasterModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-tag-form',
    loadChildren: './components/master/tag-master-form/tag-master-form.module#TagMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-tag-form/:id',
    loadChildren: './components/master/tag-master-form/tag-master-form.module#TagMasterFormModule',
    canActivate: [AuthGardService]
  },


  {
    path: 'master-interest-list',
    loadChildren: './components/master/interest-master/interest-master.module#InterestMasterModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-interest-form',
    loadChildren: './components/master/interest-master-form/interest-master-form.module#InterestMasterFormModule',
    canActivate: [AuthGardService]
  },

  // {
  //   path: 'master-category-list',
  //   component: CategoryMasterComponent,
  //   canActivate: [AuthGardService]
  // },
  {
    path: 'master-category-form',
    loadChildren: './components/master/category-master/category-master.module#CategoryMasterModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-category-form/:id',
    loadChildren: './components/master/category-master-form/category-master-form.module#CategoryMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-styleofPainting-list',
    loadChildren: './components/master/style-of-painting-master/style-of-painting-master.module#StyleOfPaintingMasterModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-styleofPainting-form/:id',
    loadChildren: './components/master/style-of-painting-master-form/style-of-painting-master-form.module#StyleOfPaintingMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-styleofPainting-form',
    loadChildren: './components/master/style-of-painting-master-form/style-of-painting-master-form.module#StyleOfPaintingMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-styleofSculpture-list',
    loadChildren: './components/master/style-of-sculpture-master/style-of-sculpture-master.module#StyleOfSculptureMasterModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-styleofSculpture-form',
    loadChildren: './components/master/style-of-sculpture-master-form/style-of-sculpture-master-form.module#StyleOfSculptureMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-styleofSculpture-form/:id',
    loadChildren: './components/master/style-of-sculpture-master-form/style-of-sculpture-master-form.module#StyleOfSculptureMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-styleofprint-list',
    loadChildren: './components/master/style-of-print-master/style-of-print-master.module#StyleOfPrintMasterModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-styleofprint-form',
    loadChildren: './components/master/style-of-print-master-form/style-of-print-master-form.module#StyleOfPrintMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-styleofprint-form/:id',
    loadChildren: './components/master/style-of-print-master-form/style-of-print-master-form.module#StyleOfPrintMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-philatelicNumismaticType-list',
    loadChildren: './components/master/philatelic-numismatic-type-master/philatelic-numismatic-type-master.module#PhilatelicNumismaticTypeMasterModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-philatelicNumismaticType-form',
    loadChildren: './components/master/philatelic-numismatic-type-master-form/philatelic-numismatic-type-master-form.module#PhilatelicNumismaticTypeMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-philatelicNumismaticType-form/:id',
    loadChildren: './components/master/philatelic-numismatic-type-master-form/philatelic-numismatic-type-master-form.module#PhilatelicNumismaticTypeMasterFormModule',
    canActivate: [AuthGardService]
  },

  {
    path: 'master-styleOfPaintingMaster-form',
    loadChildren: './components/master/style-of-painting-master-form/style-of-painting-master-form.module#StyleOfPaintingMasterFormModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'master-artist-list',
    loadChildren: './components/master/artist-master/artist-master.module#ArtistMasterModule',
  },
  {
    path: 'master-artist-form',
    loadChildren: './components/master/artist-master-form/artist-master-form.module#ArtistMasterFormModule'
  },
  {
    path: 'master-artist-form/:id',
    loadChildren: './components/master/artist-master-form/artist-master-form.module#ArtistMasterFormModule'
  },
  {
    path: 'master-consignor-list',
    loadChildren: './components/master/consignor-list/consignor-list.module#ConsignorListModule'
  },
  {
    path: 'master-consignor-form',
    loadChildren: './components/master/consignor-form/consignor-form.module#ConsignorFormModule'
  },
  {
    path: 'consignor-form/:id',
    loadChildren: './components/master/consignor-form/consignor-form.module#ConsignorFormModule'
  },
  {
    path: 'master-hsncode-list',
    loadChildren: './components/master/hsncode-master-list/hsncode-master-list.module#HsncodeMasterListModule'
  },
  {
    path: 'master-hsncode-form',
    loadChildren: './components/master/hsncode-master-form/hsncode-master-form.module#HsncodeMasterFormCModule'
  },
  {
    path: 'master-hsncode-form/:id',
    loadChildren: './components/master/hsncode-master-form/hsncode-master-form.module#HsncodeMasterFormCModule'
  },
  {
    path: 'master-location-list',
    loadChildren: './components/master/location-master/location-master.module#LocationMasterModule'
  },
  {
    path: 'master-location-form/:id',
    loadChildren: './components/master/location-master-form/location-master-form.module#LocationMasterFormModule'
  },
  {
    path: 'master-location-form',
    loadChildren: './components/master/location-master-form/location-master-form.module#LocationMasterFormModule'
  },
  {
    path: 'master-logistic-list',
    loadChildren: './components/master/logistic-master/logistic-master.module#LogisticMasterModule'
  },
  {
    path: 'master-logistic-form',
    loadChildren: './components/master/logistic-master-form/logistic-master-form.module#LogisticMasterFormModule'
  },
  {
    path: 'master-logistic-form/:id',
    loadChildren: './components/master/logistic-master-form/logistic-master-form.module#LogisticMasterFormModule'
  },
  {
    path: 'master-substatus-list',
    loadChildren: './components/master/InventorySubstatus-master-list/InventorySubstatus-list.module#InventorySubStatusListModule'
  },
  {
    path: 'master-substatus-form',
    loadChildren: './components/master/InventorySubstatus-master-form/InventorySubstatus-master-form.module#SubstatusMasterFormCModule'
  },
  {
    path: 'master-substatus-form/:id',
    loadChildren: './components/master/InventorySubstatus-master-form/InventorySubstatus-master-form.module#SubstatusMasterFormCModule'
  },
  // {
  //   path: 'add-inventory',
  //   component: AddInventoryComponent
  // },
  {
    path: 'dealer-mgmt/:id',
    loadChildren: './components/dealer-mgmt/dealer-mgmt.module#DealerMgmtModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'dealer-mgmt',
    loadChildren: './components/dealer-mgmt/dealer-mgmt.module#DealerMgmtModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'dealer-list',
    loadChildren: './components/dealer-list/dealer-list.module#DealerListModule',
    canActivate: [AuthGardService],
    data: { title: 'dealer_list' }
  },
  {
    path: 'edit-admin-profile/:id',
    loadChildren: './components/edit-admin-profile/edit-admin-profile.module#EditAdminProfileModule'
  },
  {
    path: 'edit-admin-profile',
    loadChildren: './components/edit-admin-profile/edit-admin-profile.module#EditAdminProfileModule'
  },
  {
    path: 'admin-profile/:id',
    loadChildren: './components/admin-profile/admin-profile.module#AdminProfileModule'
  },
  {
    path: 'admin-mgmt',
    loadChildren: './components/admin-mgmt/admin-mgmt.module#AdminMgmtModule'
  },
  {
    path: 'admin-client-mgmt/:id',
    loadChildren: './components/admin-client-mgmt/admin-client-mgmt.module#AdminClientMgmtModule'
  },
  {
    path: 'admin-client-mgmt',
    loadChildren: './components/admin-client-mgmt/admin-client-mgmt.module#AdminClientMgmtModule'
  },
  {
    path: 'cr-profile/:id',
    loadChildren: './components/cr-profile/cr-profile.module#CRProfileModule'
  },
  {
    path: 'admin-client-list/:id',
    loadChildren: './components/admin-client-list/admin-client-list.module#AdminClientListModule'
  },
  {
    path: 'admin-client-list',
    loadChildren: './components/admin-client-list/admin-client-list.module#AdminClientListModule'
  },
  {
    path: 'admin-cr-list',
    loadChildren: './components/admin-cr-list/admin-cr-list.module#AdminCrListModule'
  },
  {
    path: 'admin-meeting-list/:id',
    loadChildren: './components/cr-dashboard/meeting-list/meeting-list.module#MeetingListModule'
  },
  {
    path: 'admin-cr-mgmt/:id',
    loadChildren: './components/admin-cr-mgmt/admin-cr-mgmt.module#AdminCrMgmtModule'
  },
  {
    path: 'admin-cr-mgmt',
    loadChildren: './components/admin-cr-mgmt/admin-cr-mgmt.module#AdminCrMgmtModule'
  },
  {
    path: 'client-profile/:id',
    loadChildren: './components/client-profile/client-profile.module#ClientProfileModule'
  },
  {
    path: 'add-opportunity/:id/:cid',
    loadChildren: './components/add-opportunity/add-opportunity.module#AddOpportunityModule'
  },
  {
    path: 'add-opportunity/:id',
    loadChildren: './components/add-opportunity/add-opportunity.module#AddOpportunityModule'
  },
  {
    path: 'opportunity-list/:id',
    loadChildren: './components/opportunity-list/opportunity-list.module#OpportunityListModule'
  },
  {
    path: 'opportunity-item-list',
    loadChildren: './components/opportunity-item-list/opportunity-item-list.module#OpportunityItemListModule'
  },
  {
    path: 'add-interaction/:id',
    loadChildren: './components/add-interaction/add-interaction.module#AddIntractionModule'
  },
  {
    path: 'add-interaction/:id/:oid',
    loadChildren: './components/add-interaction/add-interaction.module#AddIntractionModule'
  },
  {
    path: 'add-interaction/:id/:oid/:iid',
    loadChildren: './components/add-interaction/add-interaction.module#AddIntractionModule'
  },
  {
    path: 'interaction-detail/:id/:cid',
    loadChildren: './components/interaction-detail/interaction-detail.module#InteractionDetailModule'
  },
  {
    path: 'interaction-detail/:id/:cid/:iid',
    loadChildren: './components/interaction-detail/interaction-detail.module#InteractionDetailModule'
  },
  {
    path: 'item-opportunity',
    loadChildren: './components/add-item-opportunity/add-item-opportunity.module#AddItemOpportunityModule'
  },
  {
    path: 'add-item-painting/:id',
    loadChildren: './components/inventories/add-item-painting/add-item-painting.module#AddItemPaintingModule'
  },
  // {
  //   path: 'add-item-form',
  //   component: AddItemFormComponent
  // },
  {
    path: 'add-item-painting/:id/:cid',
    loadChildren: './components/inventories/add-item-painting/add-item-painting.module#AddItemPaintingModule'
  },
  {
    path: 'add-item-sculpture/:id',
    loadChildren: './components/inventories/add-item-sculpture/add-item-sculpture.module#AddItemSculptureModule'
  },
  {
    path: 'add-item-sculpture/:id/:cid',
    loadChildren: './components/inventories/add-item-sculpture/add-item-sculpture.module#AddItemSculptureModule'
  },

  {
    path: 'print-inventory/:id',
    loadChildren: './components/inventories/print-inventory/print-inventory.module#PrintInventoryModule'
  },
  {
    path: 'print-inventory/:id/:cid',
    loadChildren: './components/inventories/print-inventory/print-inventory.module#PrintInventoryModule'
  },

  {
    path: 'rarebook-inventory/:id',
    loadChildren: './components/inventories/rarebook-inventory/rarebook-inventory.module#RarebookInventoryModule'
  },
  {
    path: 'rarebook-inventory/:id/:cid',
    loadChildren: './components/inventories/rarebook-inventory/rarebook-inventory.module#RarebookInventoryModule'
  },

  {
    path: 'textile-inventory/:id',
    loadChildren: './components/inventories/textile-inventory/textile-inventory.module#TextileInventoryModule'
  },
  {
    path: 'textile-inventory/:id/:cid',
    loadChildren: './components/inventories/textile-inventory/textile-inventory.module#TextileInventoryModule'
  },
  {
    path: 'memoribilia/:id',
    loadChildren: './components/inventories/memoribilia/memoribilia.module#MemoribiliaModule'
  },
  {
    path: 'memoribilia/:id/:cid',
    loadChildren: './components/inventories/memoribilia/memoribilia.module#MemoribiliaModule'
  },
  {
    path: 'philatelic-numismatic-inventory/:id',
    loadChildren: './components/inventories/neumismatic-philatelic-inventory/neumismatic-philatelic-inventory.module#NeumismaticPhilatelicInventoryModule'
  },
  {
    path: 'philatelic-numismatic-inventory/:id/:cid',
    loadChildren: './components/inventories/neumismatic-philatelic-inventory/neumismatic-philatelic-inventory.module#NeumismaticPhilatelicInventoryModule'
  },

  {
    path: 'furniture/:id',
    loadChildren: './components/inventories/furniture-inventory/furniture-inventory.module#FurnitureInventoryModule'
  },
  {
    path: 'furniture/:id/:cid',
    loadChildren: './components/inventories/furniture-inventory/furniture-inventory.module#FurnitureInventoryModule'
  },

  {
    path: 'fine-writing-inventory/:id',
    loadChildren: './components/inventories/fine-writing-inventory/fine-writing-inventory.module#FineWritingInventoryModule'
  },
  {
    path: 'fine-writing-inventory/:id/:cid',
    loadChildren: './components/inventories/fine-writing-inventory/fine-writing-inventory.module#FineWritingInventoryModule'
  },

  {
    path: 'science-natural-inventory/:id',
    loadChildren: './components/inventories/science-natural-inventory/science-natural-inventory.module#ScienceNaturalInventoryModule'
  },
  {
    path: 'science-natural-inventory/:id/:cid',
    loadChildren: './components/inventories/science-natural-inventory/science-natural-inventory.module#ScienceNaturalInventoryModule'
  },

  {
    path: 'jewellery-silver-inventory/:id',
    loadChildren: './components/inventories/jewellery-sliver-inventory/jewellery-sliver-inventory.module#JewellerySliverInventoryModule'
  },
  {
    path: 'jewellery-silver-inventory/:id/:cid',
    loadChildren: './components/inventories/jewellery-sliver-inventory/jewellery-sliver-inventory.module#JewellerySliverInventoryModule'
  },

  {
    path: 'classic-vintage-inventory/:id',
    loadChildren: './components/inventories/classic-vintage-inventory/classic-vintage-inventory.module#ClassicVintageInventoryModule'
  },
  {
    path: 'classic-vintage-inventory/:id/:cid',
    loadChildren: './components/inventories/classic-vintage-inventory/classic-vintage-inventory.module#ClassicVintageInventoryModule'
  },
  {
    path: 'TimepiecesAndClocks/:id',
    loadChildren: './components/inventories/timepieces-clocks/timepieces-clocks.module#TimepiecesClocksModule'
  },
  {
    path: 'TimepiecesAndClocks/:id/:cid',
    loadChildren: './components/inventories/timepieces-clocks/timepieces-clocks.module#TimepiecesClocksModule'
  },
  {
    path: 'forgot-password',
    loadChildren: './components/forgot-password/forgot-password.module#ForgotPasswordModule'
  },
  {
    path: 'validate-user',
    loadChildren: './components/validate-user/validate-user.module#ValidateUserModule'
  },
  {
    path: 'reset-password',
    loadChildren: './components/reset-password/reset-password.module#ResetPasswordModule'
  },
  {
    path: 'client-list/:id',
    loadChildren: './components/client-list/client-list.module#ClientListModule',
    data: { preload: true, delay: false },
  },
  {
    path: 'activity-tracker/:id',
    loadChildren: './components/activity-tracker/activity-tracker.module#ActivityTrackerModule',
  },
  {
    path: 'dashboard',
    loadChildren: './components/admin-dashboard/dashboard/dashboard.module#DashboardModule',
    data: { preload: true, delay: false },
  },
  {
    path: 'cr-dashboard',
    loadChildren: './components/cr-dashboard/crdashboard/crdashboard.module#CRdashboardModule'
  },
  {
    path: 'notification-list',
    loadChildren: './components/notification-list/notification-list.module#NotificationListModule',
    canActivate: [AuthGardService]
  },
  // {
  //   path: 'client-re-performance',
  //   loadChildren: () => import('./components/admin-dashboard/client-relation-performance/client-relation-performance.module').then(mod => mod.ClientRelationPerformanceModule)
  // },
  {
    path: 'login',
    loadChildren: './components/login/login.module#LoginModule'
  },
  {
    path: 'login-cr',
    loadChildren: './components/login/cr-login/cr-login.module#CrLoginModule'
  },
  {
    path: 'login-dealer',
    loadChildren: './components/login/dealer-login/dealer-login.module#DealerLoginModule'
  },
  {
    path: 'login-admin',
    loadChildren: './components/login/admin-login/admin-login.module#AdminLoginModule'
  },
  {
    path: 'login-sub-admin',
    loadChildren: './components/login/sub-admin-login/sub-admin-login.module#SubAdminLoginModule'
  },
  {
    path: 'login-restoration',
    loadChildren: './components/login/restoration-login/restoration-login.module#RestorationLoginModule'
  },
  {
    path: 'invoice-list',
    loadChildren: './components/invoice-master/invoice-list/invoice-list.module#InvoiceListModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'auction-product-Inventory-list/:Id',
    loadChildren: './components/master/auction-product-inventory/auction-product-inventory.module#AuctionProductInventoryModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'auction-product-Inventory-list/:Id/:lotId',
    loadChildren: './components/master/auction-product-inventory/auction-product-inventory.module#AuctionProductInventoryModule',
    canActivate: [AuthGardService]
  },
  // {
  //   path: 'sold-inventory-details',
  //   loadChildren: './components/manage-soldInventory/sold-inventory-details/sold-inventory-details.module#SoldInventoryDetailsModule',
  //   canActivate: [AuthGardService]
  // },
  {
    path: 'sold-inventories',
    loadChildren: './components/manage-soldInventory/sold-inventory/sold-inventory.module#SoldInventoriesModule',
    canActivate: [AuthGardService]
  },
  {
    path: 'sold-inventories/:id/:cid',
    loadChildren: './components/manage-soldInventory/sold-inventory/sold-inventory.module#SoldInventoriesModule',
    canActivate: [AuthGardService]
  },
    //prajakta
    {
      path: 'form-master',
      loadChildren: './components/Formnew/Form.module#FormModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'form-master/:id',
      loadChildren: './components/Formnew/Form.module#FormModule',
      canActivate: [AuthGardService]
    },
    // {
    //   path: 'FormNew/:id',
    //   loadChildren: './components/Formnew/Form.module#FormModule',
    //   canActivate: [AuthGardService]
    // },
    {
      path: 'form-master-list',
      loadChildren: './components/Form-Master/Form-Master.module#FormMasterListModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'auction-master-list',
      loadChildren: './components/master/auction-master/auction-master.module#AuctionMasterModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'auction-form',
      loadChildren: './components/master/auction-form/auction-form.module#AuctionFormModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'auction-form/:id',
      loadChildren: './components/master/auction-form/auction-form.module#AuctionFormModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'inventory-list-details',
      loadChildren: './components/inventory-list-details/inventory-list-details.module#InventoryListDetailsModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'inventory-list-details/:Id/:lotId',
      loadChildren: './components/inventory-list-details/inventory-list-details.module#InventoryListDetailsModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'inventory-gridview',
      loadChildren: './components/inventory-gridview/inventory-gridview.module#InventoryGridviewModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'lot-master-form',
      loadChildren: './components/master/lot-master-form/lot-master-form.module#LotMasterFormModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'lot-master-form/:id',
      loadChildren: './components/master/lot-master-form/lot-master-form.module#LotMasterFormModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'master-rack-list',
      loadChildren: './components/master/rack-master/rack-master.module#RackMasterModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'master-rack-form',
      loadChildren: './components/master/rack-master-form/rack-master-form.module#RackMasterFormModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'master-rack-form/:id',
      loadChildren: './components/master/rack-master-form/rack-master-form.module#RackMasterFormModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'master-event-list',
      loadChildren: './components/master/event-master/event-master.module#EventMasterModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'master-event-form',
      loadChildren: './components/master/event-master-form/event-master-form.module#EventMasterFormModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'master-event-form/:id',
      loadChildren: './components/master/event-master-form/event-master-form.module#EventMasterFormModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'master-reason-list',
      loadChildren: './components/master/reason-master/reason-master.module#ReasonMasterModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'master-reason-form',
      loadChildren: './components/master/reason-master-form/reason-master-form.module#ReasonMasterFormModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'master-reason-form/:id',
      loadChildren: './components/master/reason-master-form/reason-master-form.module#ReasonMasterFormModule',
      canActivate: [AuthGardService]
  },
    {
      path: 'reports-rfid',
      loadChildren: './components/reports-rfid/reports.module#RFIDReportModule',
      canActivate: [AuthGardService]
    },
    {
      path: 'rfid-dashboard',
      loadChildren: './components/rfid-dashboard/dashboard.module#RFIDDashboardModule',
      canActivate: [AuthGardService]
    },
  {
    path: 'current-bidders',
    loadChildren: './components/Reports/CurrentBidders/current-bidders.module#CurrentBidderModule',
  },
  {
    path: 'under-bidders',
    loadChildren: './components/Reports/UnderBidders/under-bidders.module#UnderBiddersModule',
  },

  // {
  //   path: 'login-cr',
  //   loadChildren: './components/login/cr-login/cr-login.module#LoginModule'
  // },
  // {
  //   path: 'login-dealer',
  //   loadChildren: './components/login/dealer-login/dealer-login.module#LoginModule'
  // },
  // {
  //   path: 'login-admin',
  //   loadChildren: './components/login/admin-login/admin-login.module#LoginModule'
  // },
  // {
  //   path: 'login-sub-admin',
  //   loadChildren: './components/login/sub-admin-login/sub-admin-login.module#LoginModule'
  // },

  // {
  //   path: '**',
  //   redirectTo: '/login',
  //   pathMatch: 'full'
  // },
  {
    path: '**',
    redirectTo: '/login-admin',
    pathMatch: 'full'
  },
  // {
  //   path: '**',
  //   redirectTo: '/login-restoration',
  //   pathMatch: 'full'
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
